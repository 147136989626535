import {RecordView} from '../src/components/ScrrenRecorder/RecordView';
// import './App.css'
function App() {
  return (
    <>
          <RecordView/>
    </>
  );
}

export default App;
