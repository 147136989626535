import { ReactMediaRecorder } from "react-media-recorder";

export const RecordView = () => (
  <div>
    <ReactMediaRecorder
      screen
      render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
        <div style={{textAlign: 'center'}}>

          <div style={{marginBottom: '20px', marginTop: '25px', fontSize: '25px' }}> Marty Recorning  </div>
   
          <button type="button" class="btn btn-success" onClick={startRecording}>Start Recording</button>       
          <br/> <br/>
          <button type="button" class="btn btn-danger" onClick={stopRecording}>Stop Recording</button>
          <br />      <br />
          <video src={mediaBlobUrl} controls autoPlay loop />
        </div>
      )}
    />
  </div>
);